<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ currentLocale == 'bn' ? headerText.text_bn : headerText.text_en }}</h2>
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item"><a href="javascript:" @click="$router.go(-1)">{{ $t('portal.service_details') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('portal.request_sponsored_training') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
          <b-row>
              <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{$t('portal.request_sponsored_training')}}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button @click="pdfExport" :class="'btn btn_add_new mr-2'">
                        {{  $t('globalTrans.export_pdf') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loading">
                        <b-row>
                          <b-col lg="12" sm="12" v-if="formData">
                            <div>
                              <p>{{ $t('portal.sd_1') }}</p>
                              <p style="margin: 0px;padding: 0px;">{{ $t('portal.sd_2') }}</p>
                              <p style="margin: 0px;padding: 0px;">{{ $t('portal.sd_3') }}</p>
                              <p>{{ $t('portal.sd_4') }}</p>
                              <p>{{ $t('portal.subject') + ' : '  + $t('portal.for_application') + ' “' + (($i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title)) + '”' }}</p>
                            </div>
                            <b-row>
                              <div class="w-100 text-left">
                                <div class="card-inside-title p-1">
                                  <h5 class="text-capitalize text-left p-2"
                                    style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.applicant_information') }}</h5>
                                </div>
                              </div>
                            </b-row>
                            <div class="mt-2">
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_type') + ' : ' + getProfessionType(formData.p_profession_type) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_name') + ' : ' + ($i18n.locale === 'bn' ? formData.applicant_name_bn : formData.applicant_name) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_designation') + ' : ' + ($i18n.locale === 'bn' ? formData.applicant_designation_bn : formData.applicant_designation) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.mobile') + ' : ' + ($i18n.locale === 'bn' ? '০' : '0') + $n(formData.mobile, { useGrouping: false }) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.email') + ' : ' + formData.email }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.name_institution') + ' : ' + ($i18n.locale === 'bn' ? formData.institution_name_bn : formData.institution_name) }}</p>
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.address_individuals_institution') + ' : ' + ($i18n.locale === 'bn' ? formData.address_bn : formData.address) }}</p>
                            </div>
                            <b-row>
                              <div class="w-100 text-left">
                                <div class="card-inside-title p-1">
                                  <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                                  style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.training_information') }}</h5>
                                </div>
                              </div>
                            </b-row>
                            <div class="mt-2">
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.training_title') + ' : ' + ($i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title) }}</p>
                              <b-table-simple bordered>
                                <b-tr>
                                    <b-th class="text-center" style="width:35%">{{ $t('portal.training_start_date') }}</b-th>
                                    <b-th class="text-center" style="width:35%">{{ $t('portal.training_end_date') }}</b-th>
                                    <b-th class="text-center" style="width:30%">{{ $t('portal.slot') }}</b-th>
                                </b-tr>
                                <b-tr v-for="(item1,index1) in formData.date_details" :key="index1">
                                    <b-td class="text-center">{{ item1.start_date | dateFormat }}</b-td>
                                    <b-td class="text-center">{{ item1.end_date | dateFormat  }}</b-td>
                                    <b-td class="text-center">{{ $n(item1.slot) }}</b-td>
                                </b-tr>
                              </b-table-simple>
                            </div>
                            <!-- <b-row>
                              <div class="w-100 text-left">
                                <div class="card-inside-title p-1">
                                  <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                                  style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.trainee_information') }}</h5>
                                </div>
                              </div>
                            </b-row> -->
                            <b-table-simple bordered v-if="formData.p_profession_type === 1">
                              <b-tr>
                                  <b-th class="text-center" style="width:20%">{{ $t('portal.user_type') }}</b-th>
                                  <b-th class="text-center" style="width:16%">{{ $t('portal.grade_from') }}</b-th>
                                  <b-th class="text-center" style="width:16%">{{ $t('portal.grade_to') }}</b-th>
                                  <b-th class="text-center" style="width:16%">{{ $t('portal.male') }}</b-th>
                                  <b-th class="text-center" style="width:16%">{{ $t('portal.female') }}</b-th>
                                  <b-th class="text-center" style="width:16%">{{ $t('globalTrans.total') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in formData.trainee_details" :key="index">
                                  <b-td class="text-center">{{ getUserType(item.user_type_id) }}</b-td>
                                  <b-td class="text-center">{{ getGradeForm(item.grade_from) }}</b-td>
                                  <b-td class="text-center">{{ getGradeTo(item.grade_to) }}</b-td>
                                  <b-td class="text-center">{{ $n(item.male_trainee) }}</b-td>
                                  <b-td class="text-center">{{ $n(item.female_trainee) }}</b-td>
                                  <b-td class="text-center">{{ $n(item.total_trainee) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-table-simple bordered v-if="formData.p_profession_type === 2 || formData.p_profession_type === 3">
                              <b-tr>
                                  <b-th class="text-center" style="width:20%">{{ $t('portal.user_type') }}</b-th>
                                  <b-th class="text-center" style="width:20%">{{ $t('portal.officer_type') }}</b-th>
                                  <b-th class="text-center" style="width:20%">{{ $t('portal.male') }}</b-th>
                                  <b-th class="text-center" style="width:20%">{{ $t('portal.female') }}</b-th>
                                  <b-th class="text-center" style="width:20%">{{ $t('globalTrans.total') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in formData.trainee_details" :key="index">
                                  <b-td class="text-center">{{ getUserType(item.user_type_id) }}</b-td>
                                  <b-td class="text-center">{{ getOfficerType(item.officer_type) }}</b-td>
                                  <b-td class="text-center">{{ $n(item.male_trainee) }}</b-td>
                                  <b-td class="text-center">{{ $n(item.female_trainee) }}</b-td>
                                  <b-td class="text-center">{{ $n(item.total_trainee) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                            <b-row>
                              <b-col md="4"></b-col>
                              <b-col md="4"></b-col>
                              <b-col md="4">
                                <p style="margin-bottom: 0px;padding-bottom: 0px; padding-top: 30px;" class="text-center">{{ $t('portal.sd_6') }}</p>
                                <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.applicant_name_bn : formData.applicant_name }}</p>
                                <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.applicant_designation_bn : formData.applicant_designation }}</p>
                                <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.address_bn : formData.address }}</p>
                                <p style="margin: 0px;padding: 0px;" class="text-center">{{ formData.created_at | dateFormat }}</p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </iq-card>
              </b-col>
          </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import ExportPdf from './export_pdf_Sponsored_training_details'
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
      return {
        headerText: { text_en: '', text_bn: '' },
        loading: false,
        formData: {}
      }
    },
    watch: {
    },
    computed: {
      userTypeList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Participant' : 'প্রশিক্ষণার্থী', text_en: 'Participant', text_bn: 'প্রশিক্ষণার্থী' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Resource Person' : 'রিসোর্স পারসন', text_en: 'Resource Person', text_bn: 'রিসোর্স পারসন' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Guest' : 'অতিথি', text_en: 'Guest', text_bn: 'অতিথি' }
        ]
      },
      professionTypeList () {
        const professionTypeList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Government Organization' : 'সরকারি প্রতিষ্ঠান', text_en: 'Government Organization', text_bn: 'সরকারি প্রতিষ্ঠান' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Private Organization' : 'বেসরকারি প্রতিষ্ঠান', text_en: 'Private Organization', text_bn: 'বেসরকারি প্রতিষ্ঠান' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Person' : 'ব্যক্তি', text_en: 'Person', text_bn: 'ব্যক্তি' }
        ]
        return professionTypeList
      },
      officerTypeList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Senior Officer' : 'ঊর্ধ্বতন কর্মকর্তা', text_en: 'Senior Officer', text_bn: 'ঊর্ধ্বতন কর্মকর্তা' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Mid-level Officer' : 'মিড লেভেল কর্মকর্তা', text_en: 'Mid-level Officer', text_bn: 'মিড লেভেল কর্মকর্তা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Junior Officer' : 'জুনিয়র কর্মকর্তা', text_en: 'Junior Officer', text_bn: 'জুনিয়র কর্মকর্তা' }
        ]
      },
      ...mapGetters({
        authUser: 'Auth/authUser'
      }),
      currentLocale () {
        return this.$i18n.locale
      },
      gradeList: function () {
        return this.$store.state.Portal.commonObj.gradeList.filter(item => item.status === 0)
      }
    },
    async created () {
      this.loadData()
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    methods: {
      getProfessionType (id) {
        const trainingType = this.professionTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getGradeForm (id) {
        const trainingType = this.$store.state.Portal.commonObj.gradeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getGradeTo (id) {
        const trainingType = this.$store.state.Portal.commonObj.gradeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getOfficerType (id) {
        const trainingType = this.officerTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getUserType (id) {
        const userType = this.userTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return userType !== undefined ? userType.text_bn : ''
        } else {
            return userType !== undefined ? userType.text_en : ''
        }
      },
      async loadData () {
        this.loading = true
        RestApi.getData(trainingElearningServiceBaseUrl, `/portal/sponsored-training-request/show/${this.$route.query.id}`).then(response => {
          if (response.success) {
            this.formData = response.data
            this.loading = false
          } else {
            this.loading = false
          }
        })
        this.loading = false
      },
      pdfExport () {
        const reportTitle = this.$t('portal.request_sponsored_training')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this)
      }
    }
}
</script>
<style>
  .alert .close {
    color: red !important;
  }
  #form fieldset {
    border: 1px solid #8f7c7c!important;
    float: left!important;
    margin-bottom: 25px!important;
    padding: 10px 0!important;
    width: 99.5%!important;
  }
  #form legend {
    background: #02813e none repeat scroll 0 0!important;
    border: 1px solid #e5e5e5!important;
    color: #fff!important;
    font-size: 13px!important;
    font-weight: 700!important;
    margin-bottom: 0!important;
    margin-left: 16px!important;
    padding: 6px 10px!important;
    width: 31%!important;
    border-radius: 0!important;
  }
  .hidden_header {
    display: none
  }

  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }

  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }

  .reportTitle {
    font-weight: bold;
    border: 1px solid;
    padding: 8px 15px 8px 15px;
    border-radius: 11px;
  }

  .titleDiv {
    margin-top: 2rem;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .report-name {
    font-weight: bold !important;
    text-transform: uppercase;
  }

  .my-btn {
    padding: 2px !important;
  }
</style>
